// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	API_BASE_URL: 'https://uat.api.mind2.me',
	API_BIOMETRIC_URL: 'https://api.bm.mind2.me',
	STRIPE_PUBLIC_KEY:
		'pk_test_51HjWebJOUNS8J4a9acZk9uvKlH5Eji4i7mpbhciTvVPwPVc2pgdRoalDUBbLBJVH2ouXG2tjFxsUR0GK27XkhyVR00178ig3Vh',
	FIREBASE_API_KEY: 'AIzaSyBYaAUGNGoxXWbqQ-6D_nxZLICMVcU1ZQI',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
